import api from '../../../api/api'

export default {
  list: async (DataInicio, DataFim, project) => {
    const response = await api.get.reportDelivery(DataInicio, DataFim, project)

    return response.data
  },

  find: async id => {
    const response = await api.get.deliveryReport(id)

    return response.data
  },

  delete: async id => {
    const response = await api.delete.delivery(id)

    return response.data
  },

  report: {
    delete: async id => {
      const response = await api.delete.deliveryReport(id)

      return response.data
    },
  },
}
